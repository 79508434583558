import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './locales/en/common.json';
import plCommon from './locales/pl/common.json';

// Initialize i18next without setting the language here
i18next
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enCommon },
      pl: { translation: plCommon },
    },
    fallbackLng: 'en', // Fallback language if no language is set
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18next;
