import "./src/styles/global.css";
import i18next from '../shared/i18n'; // Import shared i18n config
import { useTranslation } from 'react-i18next';

// This will dynamically set the language based on the project
export const onClientEntry = () => {
  const projectLang = 'en'; // Set to 'en' for project2

  // Dynamically set the language for this project
  i18next.changeLanguage(projectLang); 
};


console.log(
  `%c                                    
  █████████
    ██████████
    ██████████
    ██████████
  █████████ █                                 
`,
  "font-size: 20px;  color: #EE4A34;",
);
